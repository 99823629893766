import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import StoreContext from '~/layouts/StoreContext';

import SEO from '~/components/seo';
import { breakpoint } from '../constants/Breakpoint';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { CollectionGrid } from '~/components/CollectionListPage';
import { HeadingLarge, Subheading, BodyText } from '~/components/.base/headings';
import Hero, { HeroContainer } from '~/components/.base/hero';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';

import './collection-list.scss';
import { css } from 'styled-components';

const CollectionListPage = ({ data, location }) => {
    const {
        setHeaderWhite,
        store: { whiteHeader },
    } = useContext(StoreContext);
    useEffect(() => {
        if (!whiteHeader) {
            setHeaderWhite(true);
        }
    }, [whiteHeader]);
    return (
        <div
            className="collection-list"
            css={css`
                ${HeroContainer} {
                    @media ${breakpoint.md} {
                        height: 50vh;
                        min-height: 610px;
                    }
                }
            `}
        >
            <MailingListPopup />
            <SEO title="Collections" />
            <DeriveHeaderClasses location={location} />
            <Hero
                wrapperClass="hero"
                imageFluid={data.headerImage.childImageSharp.fluid}
                alt=""
                noOverlay
            >
                <div className="header-details">
                    <Subheading color="#fff">Our collections</Subheading>
                    <HeadingLarge color="#fff">
                        SHOP OUR <span className="red">RANGE</span>
                    </HeadingLarge>
                    <BodyText color="#fff"></BodyText>
                </div>
            </Hero>
            <CollectionGrid collections={data.allShopifyCollection} />
        </div>
    );
};
export default CollectionListPage;

export const query = graphql`
    query {
        headerImage: file(relativePath: { eq: "collectionList/header-image.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    src
                }
            }
        }
        allShopifyCollection {
            edges {
                node {
                    title
                    handle
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 400) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
CollectionListPage.propTypes = {
    data: PropTypes.shape({
        shopifyCollection: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            handle: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};
