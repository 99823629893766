import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { Heading } from '~/components/.base/headings';
import { CenteredContainer, BackgroundDiv } from '~/components/.base/containers';
import { WhiteButton } from '~/components/.base/buttons';
import no_image from '~/images/collectionList/noimage.jpeg';
import './CollectionGrid.scss';

const CollectionGrid = ({ collections }) => {
    const data = useStaticQuery(graphql`
        query MapQuery {
            map: file(relativePath: { eq: "background/weird_map_smudges/blurred_smudge_2.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    const [sortedCollections, setSortedCollections] = useState([]);
    useEffect(() => {
        if (collections && collections.edges) {
            const sort = collections.edges;
            sort.sort((a, b) => {
                if (a.node.title === 'Lobster') return 1;
                return 0;
            });
            setSortedCollections(sort);
        }
    }, [collections]);
    console.log(sortedCollections);
    return (
        <div className="col-grid">
            <BackgroundDiv left="5%" top="200px" w="100%" z="-1">
                <Image fluid={data.map.childImageSharp.fluid} alt="" className="blurredMap" />
            </BackgroundDiv>
            {sortedCollections.length ? (
                sortedCollections.map(({ node: { title, handle, image } }) => (
                    <div className="col-tile">
                        <div className="img-cont">
                            {image ? (
                                <div
                                    style={{
                                        backgroundImage: `url(${image.localFile.childImageSharp.fluid.src})`,
                                    }}
                                />
                            ) : (
                                <img src={no_image} alt="null" />
                            )}
                        </div>
                        <WhiteButton as={Link} to={`/collections/${handle}`}>
                            +
                        </WhiteButton>
                        <Heading>{title}</Heading>
                    </div>
                ))
            ) : (
                <p>No collections found!</p>
            )}
        </div>
    );
};

export default CollectionGrid;
